<template>
    <div class="page" v-if="isReady">
        <div class="info-wrapper">
            <h1>裝備面試系列</h1>

            <h2 v-if="data.tc_title">{{ data.tc_title }}</h2>
            <div class="event-date" v-if="data.event_date">
                活動於 {{ data.event_date }}
            </div>
            
            <div v-if="!isRegistered">
                <div class="detail" v-if="data.tc_description">
                    <div class="ql-container ql-snow">
                        <div class="ql-editor">
                            <div v-html="data.tc_description" class="pre"></div>
                        </div>
                    </div>
                </div>

                <div class="detail-pdf" v-if="data.pdf">
                    <div v-for="(pdf, index) in data.pdf" :key="index" class="pdf-wrapper">
                        <div class="caption" v-if="pdf.caption !== ''">{{ pdf.caption }}</div>
                        <a :href="pdf.file" target="_blank">
                            <div class="pdf">
                                <embed :src="pdf.file + '#toolbar=0&navpanes=0&view=FitH'" type="application/pdf">
                            </div>
                        </a>
                    </div>
                </div>

                <div class="detail-photo" v-if="data.photo">
                    <div v-for="(photo, index) in data.photo" :key="index" class="photo-wrapper">
                        <div class="caption" v-if="photo.caption !== ''">{{ photo.caption }}</div>
                        <div class="photo">
                            <img :src="photo.file" />
                        </div>
                    </div>
                </div>
                <div class="detail-youtube" v-if="data.youtube_link">
                    <div class="youtube">
                        <iframe :src="data.youtube_link" ></iframe>
                    </div>
                </div>
                
                <!--
                <template v-if="data.need_sign === 'Y'">
                    <div class="sign-panel" v-if="!isSigned">
                        <ul>
                            <li><input type="checkbox" v-model="confirm_read"> 本人已閱讀及明白以上內容。</li>
                        </ul>
                        <div class="error" v-if="confirm_error">請確定您已閱讀及明白以上內容</div>

                        <a @click="sign" class="btn-sign">確定簽署</a>
                    </div>
                    <div class="sign-panel" v-if="isSigned">
                        <div class="signed-message">
                            感謝您已於 {{ signed_date }} 確定簽署此通知內容。
                        </div>
                    </div>
                </template>
                -->


                <div class="control" v-if="!isRegister && can_register">
                    <input type="button" value="立即報名" @click="register" />
                </div>

                <div v-if="isRegister">
                    <div class="form">
                        <div class="legend">
                            <ul class="group">
                                <li>活動名稱</li>
                                <li>{{ data.tc_title }}</li>
                            </ul>
                            <ul class="group">
                                <li>活動日期</li>
                                <li>{{ data.event_date }}</li>
                            </ul>
                            <ul class="group">
                                <li>活動時間</li>
                                <li>{{ data.event_time }}</li>
                            </ul>
                            <ul class="group">
                                <li>活動編號</li>
                                <li>{{ data.event_code }}</li>
                            </ul>
                            <ul class="group">
                                <li><label>選擇分校</label></li>
                                <li>
                                    <app-select :value="form.branch_id" :data="branch" display_field="tc_name" @update="updateBranch" />
                                </li>
                            </ul>

                            <ul class="group">
                                <li><label>* 姓名</label></li>
                                <li>
                                    <input type="text" v-model="form.name" ref="name" />
                                    <div v-if="error === 'name'" class="error">請填寫您的姓名</div>
                                </li>
                            </ul>
                            <ul class="group">
                                <li><label>* 聯絡電話</label></li>
                                <li>
                                    <input type="text" v-model="form.phone" ref="phone" />
                                    <div v-if="error === 'phone'" class="error">請填寫聯絡電話</div>
                                </li>
                            </ul>
                            <ul class="group">
                                <li><label>* 電郵地址</label></li>
                                <li>
                                    <input type="text" v-model="form.email" ref="email" />
                                    <div v-if="error === 'email'" class="error">請填寫有效的電郵地址</div>
                                </li>
                            </ul>
                            <ul class="group">
                                <li><label>* 子女年齡</label></li>
                                <li>
                                    <input type="text" v-model="form.child_age" ref="child_age" />
                                    <div v-if="error === 'child_age'" class="error">請填寫子女年齡</div>
                                </li>
                            </ul>
                            <ul class="group">
                                <li></li>
                                <li>
                                    <input type="checkbox" v-model="form.agree_privacy" required="true" />
                                    *已細閱梓峰教育的<a class="link" href="https://dunn.hk/privacy_policy.php" target="_blank">收集個人資料聲明。</a>
                                    <div v-if="error === 'agree_privacy'" class="error">請閱讀並同意梓峰教育的收集個人資料聲明</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="error" v-if="submit_error">
                        Error
                    </div>
                    <div class="control" v-if="!submitting">
                        <input type="button" value="立即報名" @click="submit" />
                    </div>
                    <div class="loader" v-if="submitting && !submit_error">
                        <span class="icon-loading"></span><span>報名中...</span>
                    </div>
                </div>
            </div>
            <div class="successfully-page" v-if="isRegistered">
                <div class="message">
                    <h1>多謝您報名此活動</h1>
                    <div>您的報名已成功遞交，我們會盡快與您聯絡。</div>
                </div>
                <div class="control">
                    <input type="button" value="返回" @click="back" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Fn from './Fn'
import select from '@/components/elements/select'
export default {
    components:{ 'app-select':select },
    mixins:[ Fn ],
    data() {
        return {
            isReady: false,
            data: [],
            branch:[],
            error: false,
            branch_id: null,
            submitting: false,
            submit_error: false,
            isRegister: false,
            isRegistered: false,
            form: {},
            requiredField: [ 'name', 'phone', 'email', 'child_age', 'agree_privacy' ],
            can_register: false,
            confirm_read:null,
            confirm_error:false,
            isSigned: false,
            signed_date:null,
            timer:null
        }
    },
    mounted() {
        if (this.$root.updateNotification) this.$root.updateNotification()

        const params = new FormData()
        params.append('id', this.$route.params.id)
        params.append('token', this.Auth.getToken())

        this.$axios
            .post(process.env.VUE_APP_API + 'events-detail' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
            .then(response => {
                let r = response.data
                if (r.success === true) {
                    this.data = r.data
                    this.branch_id = r.branch_id
                    this.form.branch_id = this.branch_id
                    this.branch = r.branch
                    this.isReady = true
                    this.can_register = (this.data.can_register === 'Y') ? true : false
                    /*
                    if (this.data.need_sign === 'Y') {
                        this.signed_date = this.data.signed_date
                        if (this.signed_date !== undefined && this.signed_date !== '' && this.signed_date !== null) {
                            this.isSigned = true
                        }
                    }
                    */
                } else {
                    this.$router.push('/events/')
                }
            })
        
        clearTimeout(this.timer)
        this.timer = setTimeout(this.logView, 1000)
    },
    methods: {
        sign () {
            this.confirm_error = false
            if (this.confirm_read !== true) {
                this.confirm_error = true
            } else {
                this.logView()
            }
        },
        logView() {
            const params = new FormData()
            params.append('id', this.$route.params.id)
            params.append('token', this.Auth.getToken())

            this.$axios
                .post(process.env.VUE_APP_API + 'events-view' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                /*
                .then(response => {
                    let r = response.data
                    if (r.success === true) {
                        this.isSigned = true
                        let data = r.data
                        this.signed_date = data.signed_date
                        this.$root.updateNotification()
                    }
                })
                */
        },
        back() {
            this.form = { branch_id:this.branch_id }
            this.isRegistered = false
        },
        register() {
            this.isRegister = true
        },
        submit() {
            this.error = false
            for (let i in this.requiredField) {
                let field = this.requiredField[i]
                if (this.form[field] == '' || this.form[field] == undefined) {
                    this.error = field
                    this.$refs[field].focus()
                    break
                }
            }

            if (!this.error) {
                if (!this.validEmailAddress(this.form.email)) {
                    this.error = 'email'
                    this.$refs['email'].focus()
                }
            }

            if (!this.error) {
                this.submitting = true
                const params = new FormData()
                for(let i in this.form) {
                    params.append(i, this.form[i])
                }
                params.append('token', this.Auth.getToken())
                params.append('id', this.$route.params.id)

                this.$axios
                    .post(process.env.VUE_APP_API + 'event-register' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                    .then(response => {
                        let r = response.data
                        if (r.success === true) {
                            this.isRegistered = true
                        } else {
                            this.submit_error = true
                        }
                        this.submitting = false
                    })
                    .catch(function () {
                        this.submit_error = true
                        this.submitting = false
                    })
            }
        },
        updateBranch(value) {
            this.form.branch_id = value
        }
    }
}
</script>

<style scoped lang="scss">
@import 'Page.scss';
h2 { margin-top:50px; }
.event-date {
    margin-top:10px;
    text-align:center;
    text-shadow:0px 0px 6px rgba(0,0,0,0.5);
    font-size:16px;
}
.form {
    width:90%;
    max-width:890px;
    margin:auto;
    background:#0285b7;
    .group {
        margin:0px;
        padding:0px;
        list-style:none;
        display:table;
        width:100%;

        label { margin-top:10px; display:block; }
        li {
            display:table-cell;
            vertical-align:top;
            padding:10px 0px;
            &:first-child {
                width:120px;
                color:#a4e6ff;
            }
        }
    }
}
.message {
    padding:40px 0px;
}
.ql-container.ql-snow {
    border:0px;
    :deep(h1) { color:inherit; text-shadow:inherit; letter-spacing:inherit; }
    :deep(h2) { color:inherit; text-shadow:inherit; letter-spacing:inherit; }
    :deep(h3) { color:inherit; text-shadow:inherit; letter-spacing:inherit; }
}

.link { text-decoration:underline; text-shadow: none; font-style:bold; }
</style>
